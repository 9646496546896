header {
    background-color: #fff; 
}

.home-hero {
    position: relative;
    background-image: linear-gradient(to bottom, rgba($color-primary,.5), transparent), url(../img/bosco-header.jpg);
    background-size: cover;
    background-position: top center;
    height: 95vh;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);
}

.page-hero {

    position: relative;
    //background-image: linear-gradient(to bottom, rgba($color-primary,.5), transparent);
    background-size: cover;
    background-position: top center;
    background-color: $color-primary;
    height: 50rem;

    background-attachment: fixed;

    &.clip {
        &-1 {
            clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);
        }
        &-2 {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 90%, 0 100%);
        }
        &-3 {
            clip-path: polygon(0 100%, 100% 90%, 100% 0, 0 0);
        }
        &-4 {
            clip-path: polygon(0 90%, 100% 100%, 100% 0, 0 0);
        }
        &-5 {
            clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 75% 85%, 75% 100%, 50% 85%, 0% 85%);
        }
    }

    .name-hero {
        width: 90%;
    }

    // .navbar-hero a {
    //     color: #000;
    // }
}