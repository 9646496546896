/* Base Styles -------------------- */
@import url('https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i|Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
html {
    // Definisce quanto è grande un rem (10px il 62.5% del default del browser 16px)
    font-size: 62.5%;
}
body {
    font-family: 'Lora', sans-serif;
    background-color: #f7f7f7;
    font-size: 1.6rem;
    padding:3rem;
    color: $text-dark;

    @include media-breakpoint-down(md) { 
        padding: 1.5rem;
    }

    @include media-breakpoint-down(xs) { 
        padding: 0;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
}

main {
    background-color: #fff;
}

.page-hero,
.home-hero {
    .navbar-hero {
        padding-top: 5.5rem;
        .navbar-hero-left {
            width: 40%;
            float: left;
            text-align: right;
        }
        .navbar-hero-right {
            width: 40%;
            float: right;
        }
        .navbar-menu {
            display: block;
            width: 100%;
            padding: 0;

            li {
                display: inline-block;
                margin: 0 1rem;
                font-size: 1.4rem;
                text-transform: uppercase;
                font-family: $font-elements;
                font-weight: 600;
                letter-spacing: 0.1rem
            }

            // scompaiono le vodi di menu
            @include media-breakpoint-down(md) { 
                display: none;
            }
        }
        .logo-centered {
            position: absolute;
            left: 50%;
            top:2.5rem;
            transform: translateX(-50%);

            a.nav-logo{
                display: inline-block;
                transition: all .2s ease-out;

                img {
                    height: 8.5rem; 
                }
    
                &:hover {
                    transform: scale(1.1) rotate(3deg);
                }
            }
        }

        a {
            color: #fff;
        }
    }

    .name-hero {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        h1 {
            font-size: 4.5rem;
            color: rgba(#fff, .9);
        }

        img {
            max-width: 100%;
            margin-bottom: 2rem;
        }

        h2 {
            font-size: 2.5rem;
            color: rgba(#fff, .9);
            text-transform: uppercase;
            letter-spacing: 1rem;
        }
    }
}

section.static-page {
    .container {

        // Dropcaps
        &:first-child {
            p:first-child:first-letter {
                float: left;
                font-size: 8.4rem;
                line-height: 1em;
                padding-top: 0px;
                padding-right: 1rem;
                padding-left: .5rem;
                font-weight: 400;
            }
        }

        text-align: justify;
        max-width: 680px;
        font-size: 1.8rem;
        line-height: 1.6em;

        p {
            margin-bottom: 2rem;
        }

        &.container-big {
    
            text-align: justify;
            max-width: 1140px;
            font-size: 1.8rem;
            line-height: 1.6em;
            p {
                margin-bottom: 2rem;
            }
        }
    }

}

.topmmargin {
    &-sm {
        margin-top: 1rem;
    }
    &-md {
        margin-top: 2rem;
    }
    &-lg {
        margin-top: 3rem;
    }
    &-xl {
        margin-top: 4rem;
    }
}
.bottommargin {
    &-sm {
        margin-bottom: 1rem;
    }
    &-md {
        margin-bottom: 2rem;
    }
    &-lg {
        margin-bottom: 3rem;
    }
    &-xl {
        margin-bottom: 4rem;
    }
}

.card h6 {
    font-family: 'Montserrat', sans-serif;
}
ul.sidelist {
    font-family: 'Montserrat', sans-serif;
}

.social-card-header{
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 96px;
}
.social-card-header i {
    font-size: 32px;
    color:#FFF;
}
.bg-facebook {
    background-color:#3b5998;
}
.text-facebook {
    color:#3b5998;
}
.bg-google-plus{
    background-color:#dd4b39;
}
.text-google-plus {
    color:#dd4b39;
}
.bg-twitter {
    background-color:#1da1f2;
}
.text-twitter {
    color:#1da1f2;
}
.bg-pinterest {
    background-color:#bd081c;
}
.text-pinterest {
    color:#bd081c;
}
.share:hover {
        text-decoration: none;
    opacity: 0.8;
}

.sidelist {
    list-style: none;
    margin: 0 1rem;
    padding: 0;
    .side-item {
        margin-bottom: 1rem;
        a {
            padding: 1rem;
            line-height: 1;
            text-transform: uppercase;
            font-weight: 600;
            background-color: rgba(102, 153, 51, 0.95) !important;
            color: #fff;
            display: block;
            &:hover {
                background-color: rgba(102, 153, 51, 0.8) !important;
                text-decoration: none;
            }
        }
    }
}