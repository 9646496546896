#footer {
    font-family: "Montserrat", sans-serif;
    font-size: 1.3rem;
    margin-bottom: 1rem;

    .container {
        background: rgba(102, 153, 51, 0.95) !important;
        position: relative;
        padding-top: 2rem;
        padding-bottom: 1rem;
        max-width: 100% !important;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 30px 30px;
            border-color: transparent transparent #F7F7F7 transparent;
        }
        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 30px 30px 0;
            border-color: transparent transparent #F7F7F7 transparent;
        }
    }
    
    a {
        color: #ffffff;
        text-decoration: none !important;
        background-color: transparent;
        -webkit-text-decoration-skip: objects;
    }
    ul.social li{
        padding: 3px 0;
    }
    ul.social li a i {
        margin-right: 5px;
        font-size:25px;
        -webkit-transition: .5s all ease;
        -moz-transition: .5s all ease;
        transition: .5s all ease;
    }
    ul.social li:hover a i {
        font-size:30px;
        margin-top:-10px;
    }
    ul.social li a,
    ul.social li a:hover{
        color:#eeeeee;
    }
}