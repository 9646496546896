.prime-info {
    h1 {
        text-transform: uppercase;
        font-size: 2rem;
    }
    h2 {
        text-transform: uppercase;
        font-size: 1.6rem;
    }
    p {
        text-align: justify;
        font-size: 1.6rem;
        line-height: 2.7rem;
    }
}

.call-to-action {
    background-image: url(../img/bosco.jpg);
    background-size: cover;
    background-position: center center;
    padding: 8rem 0;
    clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);

    .container {
        background-color: rgba(#fff, 0.5);
        padding: 2rem;
        border-radius: 0.2rem;
    }
}