#burgherozzo {
    position: fixed;
    width: 3rem;
    height: 3rem;    
    top: 6rem;
    right: 6rem;
    padding: 0;
    z-index: 110;
    
    //box-shadow: .2rem .2rem 2rem rgba($black,0);
    transition: 0.4s;
    cursor: pointer;

    @include media-breakpoint-down(xs) { 
        top: 5rem;
        right: 5rem;
    }
    
    &.active {
        .bar {
            width: 3.8rem !important;
        }

        .bar:first-child {
            transform: rotate(45deg) translate(.5rem, .8rem);
        }
        .bar:nth-child(2) {
            transform: scaleX(0.1) translate(0, 0);
            opacity: 0;
        }
        .bar:last-child {
            transform: rotate(-45deg) translate(.6rem, -.9rem);
        }
    }

    .burgher-container {
        position: relative;  
        width: 3rem;
        height: 3rem;

        .bar {
            width: 3rem;
            height: .5rem;
            background-color: $white;
            
            transition: 0.4s;

            &:not(:last-child) {
                margin-bottom: .5rem;
            }

        }
    }
}

// Allo scroll della pagina
html.menu-open {
    body {
        overflow: hidden;
    }
    &.scrolled {
        #burgherozzo {
            width: 3rem;
            height: 3rem;
            top: 5rem;
            right: 5rem;
            border-radius: 0; 
            padding: 0;
            background-color: transparent;
    
            &:hover {
                background-color: transparent;
                transform: scale(1.2);
                box-shadow: initial;
            }
    
            .bar {
                background-color: #fff;
            }
        }
    }
}
html.scrolled {
    #burgherozzo {
        width: 6rem;
        height: 6rem;
        top: .5rem;
        right: .5rem;
        border-radius: 10rem; 
        padding: 1.75rem 1.5rem;
        background-color: rgba($white,.6);

        &:hover {
            background-color: rgba($white,1);
            transform: scale(1.2);
            box-shadow: 1rem 1rem 2rem rgba($black,0.15);
        }

        .bar {
            background-color: $color-primary;
        }
    }
}
.overlay-menu {
    display: none;
    position: fixed;
    //bottom: -150%;
    //left: 50%;
    opacity: 0;
    width: 0%;
    height: 0%;
    background-color: rgba($color-primary, .95);
    z-index: 100;
    transition: 0.4s;

    &.open {
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;       
        opacity: 1;
    } 

    &__container {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30rem;
        max-width: 90%;
        margin: 0 auto;
        transform: translate(-50%, -50%);

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style: none;
                margin: 1rem 0;

                a {
                    display: block;
                    text-align: center;
                    text-transform: uppercase;
                    padding: .8rem;
                    font-family: $font-elements;
                    font-weight: bold;
                    font-size: 2rem;
                    letter-spacing: 1px;
                    color: $white;
                    transition: 0.4s;

                    &:hover {
                        text-decoration: none;
                        transform: skewX(12deg);
                    }
                }
            }
        }

    }

    .menu-actions {
        position: absolute;
        width: 100%;
        bottom: 10.5rem;
        text-align: center;
        &__action {
            font-size: 4rem;
            line-height: 8rem;
            background-color: $white;
            color: $color-primary;
            width: 8rem;
            height: 8rem;
            border-radius: 10rem;
            transition: .4s;
            
            &.call {
                position: absolute;
                left:2.5rem;
            }
            &.email {
                position: absolute;
                right:2.5rem;
            }

            &:hover {
                transform: scale(1.2);
                box-shadow: 1rem 1rem 2rem rgba($black,0.15);
            }

            &:active {
                transform: scale(.9);
                box-shadow: .1rem .1rem 1rem rgba($black,0.35);
            }
        }
    }
}