/*.top-negative10 {
    margin-top: -10rem;
    padding-top: 18rem;
}*/

.ohidden {
    overflow: hidden;
}
.page-hero {
    .name-hero {

        h1 {
            font-size: 6rem;
            font-weight: 100;
        }

        h2.tagline {

            letter-spacing: 1px;

            &::before {
                content: '';
                display: block;
                background-color: rgba($white, .8);
                height: .3rem;
                width: 5rem;
                margin: 1rem auto;
            }
        }
    }
}