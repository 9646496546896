//Custom variables

// Descriptive color variables
$color-primary: #669933;
$color-triad-left: #A85876;
$color-triad-right: #5876A8;


$text-dark: #444;
$text-light: #fff;

// Functional color variables

// Font stacks
$font-headings: 'Montserrat', sans-serif;
$font-elements: 'Montserrat', sans-serif;
$font-text: 'Lora', serif;

// Asset paths
$path-image   :   '../img';
$path-fonts     :  '../fonts';
