.static-page .container {
    h2 {
        position: relative;
        font-size: 3rem;
        margin-bottom:25px;
        padding-bottom:15px;
        font-weight: 600;
        text-align: center;
        
        &:after {
            content: '';
            border-bottom: 3px solid #ddd;
            position: absolute;
            width: 80%;
            left: 10%;
            bottom: 0;
        }
        
    }
}

main {
    &.arioso {
        padding-top: 3rem;
        padding-bottom: 5rem;
    }
}

@media (min-width: 992px) { 
    main {
        &.arioso {
            padding-top: 8rem;
            padding-bottom: 7rem;
        }
    }
}

.c2a {
    clear: both;
    margin-top: 5rem;
    background: #fff;
    padding: 1.4rem;
    border-radius: 1rem;
    box-shadow: 0 0 1.5rem #f0f0f0;
    transition: all 0.3s ease-in-out;
    &:hover {
        box-shadow: 0 0 2rem #ddd;
    }
    .pull-right {
        float: right
    }
    img {
        max-width: 100%;
    }
    a.btn-arrow {
        height: 100%;
        color: #6E9E3D;
        i {
            font-size: 3.5rem;
            margin: 0;
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
}

.font-alt {
    font-family: "Montserrat", sans-serif;
}


.blog-post {
    .page-hero .name-hero h1 {
        font-weight: 700;
        font-size: 5rem;
        max-width: 1000px;
        margin: auto;
    }
}
.blog-posts {
    .page-hero .name-hero h1 {
        font-weight: 700;
        font-size: 5rem;
        max-width: 1000px;
        margin: auto;
    }
    .blog-posts-page {
        img {
            max-width: 100%;
        }
    }
}

.bot-{
    &marg-{
        &20 {
            margin-bottom: 20px;
        }
    }
}