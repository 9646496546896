.button {
    display: inline-block;
    text-transform: uppercase;
    font-family: $font-elements;
    font-weight: 400;
    color: #000;
    border: 0.1rem solid;
    border-color: #000;
    padding: 1rem;
    transition: all .2s ease-out;
    line-height: 1;

    &:hover {
        text-decoration: none;
        background-color: #000;
        color: #fff;
        transform: translateY(-0.4rem);
        box-shadow: 0rem 1rem 4rem rgba(#000,0.2);
    }

    
}

.button-primary {
    color: $color-primary;
    border-color: $color-primary;
    &:hover {
        background-color: $color-primary;
        color: #fff;
    }
}
